import { Button, Column, ERowJustification, ETheme, Loader, Row, ThemeContext } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconBellSolid, IconMoonLine, IconSickLine, IconSmileGrinLine, IconSunLine } from '@assets/images/svg';
import { useRawEvents } from '@hooks/app/events';
import { useStreamerPageSettings } from '@hooks/app/settings/StreamerPageSettings';
import { EventsFilterPreset } from '@typings';
import { Empty, EmptyPage, EventItem, FilterButton } from '@views';

import s from './EventsPage.scss';

interface IEventsPageProps {
    hideThemeSwitch?: boolean;
    useTheme?: ETheme;
}

export const EventsPage = (props: IEventsPageProps) => {
    const { t } = useTranslation();
    const { updateChannel } = useStreamerPageSettings();

    const { hideThemeSwitch, useTheme } = props;

    const EVENT_TYPE_FILTERS = [
        { caption: t('common.purchases'), value: EventsFilterPreset.PURCHASES },
        { caption: t('common.bonuses'), value: EventsFilterPreset.BONUSES },
        { caption: t('common.stickers'), value: EventsFilterPreset.STICKERS },
        { caption: t('common.meme-cannon'), value: EventsFilterPreset.MEME_CANNON },
        { caption: t('common.fullscreen'), value: EventsFilterPreset.FULLSCREEN },
        { caption: t('common.moderation'), value: EventsFilterPreset.MODERATION },
    ];

    const {
        currentUser,
        events,
        loadedEvents,
        isLoading,
        changeFilters,
        replayClick,
        toggleDisableState,
    } = useRawEvents();

    const [stickersDisabled, setStickersDisabled] = useState(false);

    const { theme, changeTheme } = useContext(ThemeContext);

    const switchStickersEnabled = () => {
        void updateChannel({ disableStickers: !stickersDisabled });
    };

    const items = events.concat(loadedEvents).map((event, idx) => {
        return (
            <EventItem
                key={idx}
                className={s.eventItem}
                currentUser={currentUser}
                event={event}
                onReplayClick={replayClick}
                onToggleDisableStateClick={toggleDisableState}
            />
        );
    });

    useEffect(() => {
        if (currentUser) {
            setStickersDisabled(currentUser.channel.disableStickers);
        }
    }, [currentUser]);

    useLayoutEffect(() => {
        if (useTheme) {
            changeTheme(useTheme, true);
        }
    }, []);

    return (
        <EmptyPage>
            <Column grow className={s.eventsContainer}>
                <Row columnGap={7} justification={ERowJustification.END} padding={10} className={s.panel}>
                    <Column>
                        <Button
                            className={cn(s.stickersSwitchButton, {
                                [s.stickersSwitchButtonEnabled]: !stickersDisabled,
                                [s.stickersSwitchButtonDisabled]: stickersDisabled,
                            })}
                            icon={stickersDisabled ? <IconSickLine/> : <IconSmileGrinLine/>}
                            onClick={switchStickersEnabled}
                        />
                    </Column>
                    <Column grow/>
                    { !hideThemeSwitch &&
                        <Button
                            className={s.themeSwitchButton}
                            icon={theme === ETheme.LIGHT ? <IconMoonLine/> : <IconSunLine/>}
                            onClick={() => changeTheme(theme === ETheme.LIGHT ? ETheme.DARK : ETheme.LIGHT )}
                        />
                    }
                    <FilterButton
                        valuesList={EVENT_TYPE_FILTERS}
                        onSelectionChange={(val) => changeFilters(val.map((v) => v.value))}
                    />
                </Row>
                <Column rowGap={10} className={s.eventsList}>
                    {isLoading
                        ? <Loader/>
                        : items.length > 0
                            ? items
                            : <Empty
                                icon={<IconBellSolid/>}
                                iconSize={50}
                                text={t('events.emptyText')}
                            />
                    }
                </Column>
            </Column>
        </EmptyPage>
    );
};
