import React from 'react';

import s from './ControlItem.scss';

interface IControlItemProps {
    title: string;
    icon: JSX.Element;
    onClick?(): void;
}

export const ControlItem = (props: React.PropsWithChildren<IControlItemProps>) => {
    const {
        title,
        icon,
        children,
        onClick,
    } = props;

    return (
        <div
            className={s.root}
            onClick={onClick}
        >
            <div className={s.icon}>
                {icon}
            </div>
            <div className={s.title}>
                {title}
            </div>

            {children}
        </div>
    );
};
