import React from 'react';

import { ITag } from '@typings';

import s from './TagsList.scss';
import { TagItem } from '../TagItem';


interface ITagsListProps {
    tags: ITag[];
}

const MAX_TAGS_COUNT = 5;

export const TagsList = (props: ITagsListProps) => {
    const { tags } = props;

    return (
        <div className={s.root}>
            {tags.slice(0, MAX_TAGS_COUNT).map((item) => (
                <TagItem
                    key={item._id}
                    tag={item}
                />
            ))}
        </div>
    );
};
