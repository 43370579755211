// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Controls__root--R4mal {
  position: relative;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/base/views/modals/StickerPopup/components/Bottom/components/Controls/Controls.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;AACJ","sourcesContent":[".root {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Controls__root--R4mal`
};
export default ___CSS_LOADER_EXPORT___;
