import { Column, EColumnAlignment, EColumnJustification, Row } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { ISticker, SizeCalculationBase } from '@typings';
import { EStickerPopupControlsMode, Sticker } from '@views';

import s from './Content.scss';
import { IStickerPopupDataExtended } from '../../types';
import {
    Controls,
    ControlsSend,
    ControlsStreamer,
    ControlsViewer,
    SentCounter,
} from '../Bottom/components';

interface IContentProps extends IStickerPopupDataExtended {
    sticker: ISticker;
    updateSticker(stickerData: ISticker): void;
}

export const Content = (props: IContentProps) => {
    const {
        sticker,
        controlsMode,
        currentUser: { currentUser },
        updateSticker,
    } = props;
    const [isPreviewLoaded, setIsPreviewLoaded] = useState(false);

    useEffect(() => {
        if (sticker.stickerPreview) {
            const img = new Image();

            img.onload = () => setIsPreviewLoaded(true);
            img.src = sticker.stickerPreview;
        }
    }, [sticker?.stickerPreview]);

    return (
        <Row className={cn(
            s.root,
            {
                [s.streamer]: controlsMode === EStickerPopupControlsMode.STREAMER,
                [s.viewer]: controlsMode === EStickerPopupControlsMode.VIEWER,
                [s.send]: controlsMode === EStickerPopupControlsMode.SEND,
            },
        )}>
            <Column className={s.left}>
                <div
                    className={cn(s.stickerBg, { [s.stickerBgReady]: isPreviewLoaded })}
                    style={{
                        backgroundImage: `url("${sticker.stickerPreview}")`,
                    }}
                />

                <Sticker
                    className={s.sticker}
                    classNameVideo={s.stickerVideo}
                    sticker={sticker}
                    withSound
                    loop={true}
                    sizeCalculationBase={SizeCalculationBase.HEIGHT}
                    useRecalculateDimensions={false}
                    forceEnabled={true}
                />
            </Column>

            <Column
                className={s.right}
                alignment={EColumnAlignment.BEGIN}
                justification={EColumnJustification.CENTER}
            >
                <div className={s.stickerName}>{sticker.name}</div>

                <Row className={s.controls}>
                    {controlsMode === EStickerPopupControlsMode.SEND && (
                        <ControlsSend {...props}/>
                    )}

                    {controlsMode === EStickerPopupControlsMode.VIEWER && (
                        <ControlsViewer
                            sticker={sticker}
                            currentUser={currentUser}
                            updateSticker={updateSticker}
                        />
                    )}

                    {controlsMode === EStickerPopupControlsMode.STREAMER && (
                        <ControlsStreamer
                            sticker={sticker}
                            currentUser={currentUser}
                            updateSticker={updateSticker}
                        />
                    )}
                </Row>

                <SentCounter sticker={sticker}/>

                <Controls {...props}/>
            </Column>
            {/* <div className={s.right}/>*/}
        </Row>
    );
};
