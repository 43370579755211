import { IModalContentWithOptions, useModals } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React, { useEffect, useState } from 'react';

import { ISticker } from '@typings';

import s from './useStickerPopup.scss';
import { StickerPopup } from '../../StickerPopup';
import { IStickerPopupDataExtended } from '../../types';


interface IStickerPopupParams extends IStickerPopupDataExtended {}

export const useStickerPopup = (params: IStickerPopupParams) => {
    const {
        currentUser: { currentUser },
        balance: { balance },
        stickers: { topic },
        onClose = noop,
    } = params;
    const {
        showModal,
        setContent,
        closeModal,
    } = useModals();
    const [sticker, setSticker] = useState<ISticker>();
    const updateSticker = (stickerData: ISticker) => setSticker(stickerData);
    const content = (
        <StickerPopup
            {...params}
            sticker={sticker}
            updateSticker={updateSticker}
            closeModal={closeModal}
            onClose={onClose}
        />
    );

    const showStickerPopup = () => {
        const stickerPopup: IModalContentWithOptions<ISticker> = {
            content,
            options: {
                className: s.noBgModal,
                bodyClassName: s.noPaddingModal,
            },
        };
        showModal(stickerPopup);
    };

    const setStickerPopupItem = (stickerItem: ISticker) => setSticker(stickerItem);

    useEffect(
        () => {
            if (sticker) {
                setContent(content);
            }
        },
        [
            sticker,
            currentUser,
            balance,
            topic,
        ],
    );

    return {
        showStickerPopup,
        setStickerPopupItem,
    };
};
