import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconExclamationOctagonLine } from '@assets/images/svg';
import { ISticker } from '@typings';

import { ControlItem } from '../ControlItem';
import { ControlPopupReport } from '../ControlPopupReport';

interface IControlReportProps {
    sticker: ISticker;
    isPopupDisplayed: boolean;
    onClick(): void;
    onClose(): void;
}

export const ControlReport = (props: IControlReportProps) => {
    const {
        sticker,
        isPopupDisplayed,
        onClick,
        onClose,
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.bottom.controls.${id}`);

    return (
        <ControlItem
            title={t('report')}
            icon={<IconExclamationOctagonLine/>}
            onClick={onClick}
        >
            {isPopupDisplayed && (
                <ControlPopupReport
                    sticker={sticker}
                    onClose={onClose}
                />
            )}
        </ControlItem>
    );
};
