import { ETheme } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useEffect } from 'react';

import { ISticker } from '@typings';

import {
    Bottom,
    Content,
    Top,
} from './components';
import s from './StickerPopup.scss';
import { IStickerPopupDataExtended } from './types';


interface IStickerPopupProps extends IStickerPopupDataExtended {
    sticker?: ISticker;
    updateSticker(stickerData: ISticker): void;
    closeModal(): void;
}

export const StickerPopup = (props: IStickerPopupProps) => {
    const {
        sticker,
        closeModal,
        onClose = noop,
    } = props;
    const extendedProps = {
        ...props,
        sticker,
        closeModal,
    } as IStickerPopupProps & { sticker: ISticker; };

    const handleWindowKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            closeModal();
            onClose();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleWindowKeyDown);

        return () => window.removeEventListener('keydown', handleWindowKeyDown);
    }, []);

    return (
        <div className={cn(s.root, `theme-${ETheme.DARK}`)}>
            {sticker && (
                <>
                    <Top {...extendedProps}/>
                    <Content {...extendedProps}/>
                    <Bottom {...extendedProps}/>
                </>
            )}
        </div>
    );
};
