import { Column, Loader } from '@hyperclap/ui';
import React, { useEffect } from 'react';

import { IconHeartSolid, IconSendMessageLine } from '@assets/images/svg';
import { TApp, useAppActions, useElementOnScreen } from '@hooks';
import { FavoritesMode } from '@hooks/app/personal-area';
import { useAppSelector } from '@hooks/store/AppSelector';
import { ISticker } from '@typings';
import {
    Empty,
    EStickerPopupControlsMode,
    ITabParams,
    StickersGrid,
    Tabs,
    useStickerPopup,
} from '@views';

import s from './Desktop.scss';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        personalArea: {
            favorites: {
                favoritesMode,
                isStickersFetching,
                isEndReached,
                stickers,
                deleteLoadedSticker,
                loadNextPage,
                changeFavoritesMode,
                updateLoadedSticker,
            },
        },
        stickers: {
            switchStickerFavoriteState,
            switchStickerInChannelState,
            changeStickerCustomSettings,
            deleteSticker,
        },
        translation: {
            t,
        },
    } = props;

    const TABS: Array<ITabParams<FavoritesMode>> = [
        { caption: t('personal-area.favorites.favorites'), tag: FavoritesMode.FAVORITES },
        { caption: t('personal-area.favorites.sent'), tag: FavoritesMode.SENT },
    ];

    const playSoundOnStickerHover = useAppSelector((state) => state.app.playSoundOnStickerHover);
    const { switchPlaySoundOnStickerHover } = useAppActions();

    const {
        elementRef: watchdogRef,
        isOnScreen: needToLoadPage,
    } = useElementOnScreen<HTMLDivElement>({ threshold: 0 });

    const updateStickerFavoriteState = async (sticker: ISticker) => {
        return await switchStickerFavoriteState(sticker, (sticker) => {
            updateLoadedSticker(sticker);
            if (!sticker.isFavorite) deleteLoadedSticker(sticker);
        });
    };

    const updateStickerInChannelState = async (sticker: ISticker) => {
        return await switchStickerInChannelState(sticker, updateLoadedSticker);
    };

    const {
        showStickerPopup,
        setStickerPopupItem,
    } = useStickerPopup({
        ...props,
        updateCustomSettings: changeStickerCustomSettings,
        updateFavoriteState: updateStickerFavoriteState,
        updateInChannelState: updateStickerInChannelState,
        deleteSticker: deleteSticker,
        controlsMode: EStickerPopupControlsMode.VIEWER,
    });

    const onTabSelected = (tab: FavoritesMode = FavoritesMode.FAVORITES) => {
        changeFavoritesMode(tab);
    };

    const onStickerClick = (sticker: ISticker) => {
        setStickerPopupItem(sticker);
        showStickerPopup();
    };

    useEffect(() => {
        if (needToLoadPage) {
            void loadNextPage();
        }
    }, [needToLoadPage]);

    return (
        <Column margin={{ top: 23 }}>
            <Tabs activeTab={favoritesMode} onSelectionChange={onTabSelected} tabs={TABS}/>
            { stickers.length > 0
                ? <StickersGrid
                    stickers={stickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    onStickerClick={onStickerClick}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
                : isStickersFetching
                    ? <Loader margin={{ top: 70 }} />
                    : <Empty
                        padding={{ top: 70 }}
                        icon={favoritesMode === FavoritesMode.FAVORITES ? <IconHeartSolid/> : <IconSendMessageLine/>}
                        iconSize={50}
                        text={t(
                            favoritesMode === FavoritesMode.FAVORITES
                                ? 'personal-area.favorites.noFavoritesStickers'
                                : 'personal-area.favorites.noSentStickers',
                        )}
                    />
            }

            {!isStickersFetching && !isEndReached &&
                <div className={s.nextPageWatchdog}>
                    <div className={s.nextPageWatchdogMarker} ref={watchdogRef}/>
                </div>
            }
        </Column>
    );
};
