import { ERowJustification, Row } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { ISticker } from '@typings';

import s from './Bottom.scss';
import {
    // Controls,
    // ControlsSend,
    // ControlsStreamer,
    // ControlsViewer,
    Flags,
    // SentCounter,
    TagsList,
} from './components';
import { EStickerPopupControlsMode } from '../../enums';
import { IStickerPopupDataExtended } from '../../types';

interface IBottomProps extends IStickerPopupDataExtended {
    sticker: ISticker;
    updateSticker(stickerData: ISticker): void;
}

export const Bottom = (props: IBottomProps) => {
    const {
        sticker,
        // currentUser: { currentUser },
        // updateSticker,
        controlsMode,
    } = props;

    return (
        <div className={cn(
            s.root,
            {
                [s.streamer]: controlsMode === EStickerPopupControlsMode.STREAMER,
                [s.viewer]: controlsMode === EStickerPopupControlsMode.VIEWER,
                [s.send]: controlsMode === EStickerPopupControlsMode.SEND,
            },
        )}>
            <Row
                className={s.bottomLine}
                justification={ERowJustification.SPACE_BETWEEN}
            >
                <div className={s.bottomLineLeft}>
                    {sticker.tags && (
                        <TagsList tags={sticker.tags}/>
                    )}
                </div>
                <div className={s.bottomLineRigth}>
                    <Flags sticker={sticker}/>
                </div>
            </Row>
        </div>
    );
};
